import {Cookies} from 'react-cookie';
import {uuid} from "../../utils/Utils";
import CircularJSON from "circular-json";

const Logger = {
    client_id: "NA",
    auth_id: "NA",
    arr: [],
    setClientID: function( client_id ){
        this.client_id = client_id;
    },
    setAuthID: function( auth_id ){
        this.auth_id = auth_id;
    },
    push: function(){
        if(arguments.length > 1){
            if(arguments[0] !== 'log' && arguments[0] !== 'error' && arguments[0] !== 'info'){
                if(window.Config && window.Config.REACT_APP_DEBUG_MODE) console.warn('// Logger.push 1st argument needs to be either log, error or info.');
                return;
            }
            let ts = new Date().getTime()
            if(window.Config && window.Config.REACT_APP_DEBUG_MODE) console.warn('event stored with timestamp: ',ts);
            let tmp = [ts];
            for(let i = 0; i<arguments.length; i++){
                tmp.push(arguments[i]);
            }
            this.arr.push(tmp);
        }else{
            if(window.Config && window.Config.REACT_APP_DEBUG_MODE) console.warn('// Logger.push needs at least 2 arguments to build the message [type, title [,args_msg_body] ].');
        }
    },
    flush: function(){
        let that = this;
        for(let i = 0; i<this.arr.length;i++){
            this.writeMessage(this.arr[i][1], this.arr[i].slice(2), this.arr[i][0]);
        }
        this.arr = [];
    },
    smapi: function(o){
        /*
         * event-type : "client", "internal" or "request"
         * client_name : hardcoded always JSClient within this application
         * event-context : For client events, the context in what a certain action on the client happens, e.g. the "login flow".
         * event-action : message to add to the log
         * logTag : specific to us : type of log entry : INFO = informational , PERF = performance, etc
         * context : specific to us : indicates where the event occured
         * logger_name : specific to us : the log that this entry belongs to
         * "x-vf-trace-timestamp": used to be new Date().toISOString() now it's with getTime()
         *
            NEW EVENT PROPERTIES :

            context 	  -->  now is  --> 	page-name
            event-context -->  now is  --> 	event-description --> e.g. the "login flow"
            event-action  -->  now is  -->  event-element as  --> full message

            for the x-vf-trace-session-id you could use the same that the sec lib is sending
         *
         *
         */
        let evt = {
            "xVfEvents":{
                "event-type": "client",
                "client_name": "JSClient",

                "x-vf-trace-timestamp": o.ts,
                "x-vf-trace-user-agent": navigator.userAgent,

                "page-name": process.env.REACT_APP_ENV.toUpperCase() + " - " + window.location.href + " - " + o.page,

                "event-description": o.title,
                "event-element": o.msg === "" ? "NA" : o.msg,

                "logTag": "INFO",

                "logger_name": "com.vodafone.id.gateway.frontend",

                "region": Cookies.Language
            }
        };

        // "x-vf-trace-subject-id": "telmo.dias@vodafone.com",
        if(o.hasOwnProperty('subjectId') && o.subjectId!=='')
            evt.xVfEvents['x-vf-trace-subject-id'] = o.subjectId;
        // For request events, the identifier of the component a request was sent to, i.e. the target server component.
        if(o.hasOwnProperty('destination') && o.destination!=='')
            evt.xVfEvents['x-vf-trace-destination'] = o.destination;

        // "x-vf-trace-subject-region": "country-code:PT",
        evt.xVfEvents['x-vf-trace-subject-region'] = ((o.hasOwnProperty('region') && o.region !== '') ? o.region : "EN");
        // specific to us : indicates the type of log ex: debug, info, error
        evt.xVfEvents['level'] = ((o.hasOwnProperty('level') && o.level !== '') ? o.level.toUpperCase() : "INFO");
        // Every event MUST expose a globally unique transaction ID to make it identifyable.
        evt.xVfEvents['x-vf-trace-transaction-id'] = ((o.hasOwnProperty('transactionId') && o.transactionId!=='') ? o.transactionId : uuid());

        let eventElement = o.msg === "" ? "NA" : o.msg; 
        let eventDescription =  o.title;
        let onPage = process.env.REACT_APP_ENV.toUpperCase() + ' - ' + window.location.href + ' - ' + o.page;
        let onSubPage = process.env.REACT_APP_ENV.toUpperCase() + ' - ' + window.location.href + ' - ' + o.page;
        //let customJson = evt.xVfEvents;

        let customJson = {
            "authorization-id": window.getAuthId() != "NA" ? window.getAuthId() : this.auth_id,
            "client_id": this.client_id
        }

        if( window.submitter && navigator.cookieEnabled && window.Config && window.Config.REACT_APP_IS_SMAPI_ENABLED ) {
            try {
                window.submitter.logCustomEvent(eventElement, eventDescription, onPage, '', customJson);
            }catch(e) {
                console.log("SMAPI Error:", e);
            }
        }

    },
    writeMessage: function(type,args,ts){
        //let title = type.toUpperCase()+' : '+args[0];
        let title = args[0];//updated for CI-13038
        let str = '';
        let pageName = '';

        if(args.length > 1){
            for (let i = 1; i < args.length; i++) {
                if(typeof args[i] === 'object'){

                    //str += ' '+JSON.stringify(args[i]);
                    if(args[i] !== null && args[i].hasOwnProperty('page')){
                        pageName = args[i].page;
                    }
                    else{
                        str += ' '+CircularJSON.stringify(args[i]);
                    }

                }else{
                    str += ' '+String(args[i]);
                }
            }
        }

        if(window.Config && window.Config.REACT_APP_DEBUG_MODE){
            console[type](title,str);
        }

        if(window.Config && window.Config.REACT_APP_IS_SMAPI_ENABLED && (type === 'info' || type === 'warn') ) {
            let o = {
                title: title,
                msg: str,
                level: type,
                ts: ((typeof ts !== 'undefined') ? ts : new Date().getTime()),
                page:pageName
            };
            this.smapi(o);
         }
     },
    log: function() {
        if(window.Config && window.Config.REACT_APP_DEBUG_MODE){
            console.log(arguments[0]);
        }
    },
    info: function(){
        if(arguments.length > 0){
            this.writeMessage('info',arguments);
        }else{
            if(window.Config && window.Config.REACT_APP_DEBUG_MODE){
                console.warn('// Logger.log needs at least 1 argument to build the message.');
            }
        }
    },
    warn: function(){
        if(arguments.length > 0){
            this.writeMessage('warn',arguments);
        }else{
            if(window.Config && window.Config.REACT_APP_DEBUG_MODE){
                console.warn('// Logger.warn needs at least 1 argument to build the message.');
            }
        }
    },
    error: function(){
        if(arguments.length > 0){
            this.writeMessage('error',arguments);
        }else{
            if(window.Config && window.Config.REACT_APP_DEBUG_MODE){
                console.warn('// Logger.error needs at least 1 argument to build the message.');
            }
        }
    }
};
export default Logger;