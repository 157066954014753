import React, { createContext, useReducer } from "react";
import * as actionTypes  from '../constants/actionTypes';

export const StoreContext = createContext();

const url = new URL(window.location.href);
const urlHash = window.location.hash;
const urlHashString = urlHash.startsWith('#/?') ? urlHash.slice(2) : "";
const urlHashParams = new URLSearchParams(urlHashString);

const getTRX = () => {
	let trx = window.location.href.split("trx/")[1]

	if (trx !== undefined && trx.split('/').length > 0) {
		trx = trx.split('/')[0];
	}

	return trx;
}

const initialState = {
	brandtmpl:  window.location.pathname !== "/" && window.location.pathname !== "/authorize" && window.location.pathname !== "" ? window.location.pathname.replace(/\//g, "")
				: url.searchParams.get("page_look") ? url.searchParams.get("page_look")
				: urlHashParams.get('page_look') ? urlHashParams.get('page_look')
				: "default",
	trx: getTRX(),
	
	language: "en",
	loading: true,
	
	data: false,
	error: false,

	popup: false,
	banner: true,
	prompt: false,

	msisdnIndex: 0
};

const store = createContext(initialState);
const { Provider } = store;

const initialReducer = (state, action) => {
		switch (action.type) {
			case actionTypes.ERROR:
				return {
					...state,
					language: action.language,
					loading: false,
					error: true
				};
			case actionTypes.LANGUAGE:
				return {
					...state,
					language: action.language,
					loading: false
				};
			case actionTypes.LOADING:
				return {
					...state,
					loading: action.loading ? action.loading : true
				};
			case actionTypes.RESPONSE:
				return {
					...state,
					loading: false,
					data: action.data,
					msisdnIndex: action.msisdnIndex ? action.msisdnIndex : 0
				};
			case actionTypes.POPUP:
				return {
					...state,
					loading: false,
					popup: action.popup,
					banner: false
				};
			case actionTypes.BANNER:
				return {
					...state,
					loading: false,
					popup: false,
					banner: action.banner
				};
			case actionTypes.PROMPT:
				return {
					...state,
					loading: false,
					prompt: action.prompt
				};
			case actionTypes.CDN_CONTENT:
				return {
					...state,
					loading: false,
					tnc: action.tnc,
					pp: action.pp,
				};
			case actionTypes.TNC_CONTENT:
				return {
					...state,
					loading: false,
					tnc: action.tnc,
				};
			case actionTypes.PP_CONTENT:
				return {
					...state,
					loading: false,
					pp: action.pp,
				};
			default:
				return state;
		}
};

export const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(initialReducer, initialState );
    const value = { state, dispatch };

    return (
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    );
};