export const LOADING = "LOADING";

export const LANGUAGE = "LANGUAGE";
export const RESPONSE = "RESPONSE";

export const BANNER = "BANNER";
export const POPUP = "POPUP";
export const PROMPT = "PROMPT";

export const ERROR = "ERROR";

export const CDN_CONTENT = "CDN_CONTENT";
export const PP_CONTENT = "PP_CONTENT";
export const TNC_CONTENT = "TNC_CONTENT";